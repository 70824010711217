var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "createAccount",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "p",
                {
                  staticClass: "modal-title text-bold",
                  attrs: {
                    id: "exampleModalCenterTitle",
                    "data-translate": "_send.setting",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("__Createnewlink")) + " ")]
              ),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _vm.isLoading
                ? _c("div", { staticClass: "text-center" }, [_vm._m(1)])
                : _vm._e(),
              !_vm.isLoading
                ? _c("div", { staticClass: "container-fluid" }, [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                      _vm._v(" " + _vm._s(_vm.$t("__requiredItem")) + " "),
                    ]),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.adminId.value,
                            expression: "formInput.adminId.value",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.formInput.adminId.error },
                        attrs: { type: "hidden" },
                        domProps: { value: _vm.formInput.adminId.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formInput.adminId,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.accountWebhookUrl.value,
                            expression: "formInput.accountWebhookUrl.value",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.formInput.accountWebhookUrl.error,
                        },
                        attrs: { type: "hidden" },
                        domProps: {
                          value: _vm.formInput.accountWebhookUrl.value,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formInput.accountWebhookUrl,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "col-4 pt-2",
                          attrs: { "data-translate": "_send.setting" },
                        },
                        [_vm._v(_vm._s(_vm.$t("__accountId")))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "mb-2 col-7",
                          attrs: { id: "story-create-title" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.formInput.accountId.value) + " "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          attrs: { id: "story-create-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editName()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-pencil-alt cursor-pointer pl-1",
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.accountId.value,
                            expression: "formInput.accountId.value",
                          },
                        ],
                        staticClass: "form-control mb-2 col-8 element-hide",
                        class: { "is-invalid": _vm.formInput.accountId.error },
                        attrs: { type: "text", id: "story-create-input" },
                        domProps: { value: _vm.formInput.accountId.value },
                        on: {
                          keypress: function ($event) {
                            return _vm.confirmChangeName($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formInput.accountId,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "col-4 pt-2",
                          attrs: { "data-translate": "_send.setting" },
                        },
                        [
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("__name")) + " "),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.accountName.value,
                            expression: "formInput.accountName.value",
                          },
                        ],
                        staticClass: "form-control mb-2 col-8",
                        class: {
                          "is-invalid": _vm.formInput.accountName.error,
                        },
                        attrs: { id: "account-name", type: "text" },
                        domProps: { value: _vm.formInput.accountName.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formInput.accountName,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "col-4 pt-2",
                          attrs: { "data-translate": "_send.setting" },
                        },
                        [_vm._v(_vm._s(_vm.$t("__ConnectionType")))]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formInput.accountType.value,
                              expression: "formInput.accountType.value",
                            },
                          ],
                          staticClass: "custom-select mb-2 col-8",
                          class: {
                            "is-invalid": _vm.formInput.accountType.error,
                          },
                          attrs: { id: "account-type" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formInput.accountType,
                                  "value",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeWebhook()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "line" } }, [
                            _vm._v(_vm._s(_vm.$t("__line"))),
                          ]),
                          _c("option", { attrs: { value: "wechat" } }, [
                            _vm._v(_vm._s(_vm.$t("__wechat"))),
                          ]),
                          _c("option", { attrs: { value: "fb" } }, [
                            _vm._v(_vm._s(_vm.$t("__facebook"))),
                          ]),
                          _c("option", { attrs: { value: "ig" } }, [
                            _vm._v(_vm._s(_vm.$t("__instagram"))),
                          ]),
                        ]
                      ),
                      _c("label", { staticClass: "col-4 pt-2" }, [
                        _vm._v(_vm._s(_vm.$t("__accountClone"))),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formInput.accountClone.value,
                              expression: "formInput.accountClone.value",
                            },
                          ],
                          staticClass: "custom-select mb-2 col-8",
                          attrs: { id: "account-clone" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.formInput.accountClone,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Choose Account"),
                          ]),
                          _vm._l(_vm.allAccounts, function (account, idx) {
                            return _c(
                              "option",
                              {
                                key: idx,
                                domProps: { value: account.accountId },
                              },
                              [_vm._v(" " + _vm._s(account.accountName) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "col-12 pt-4" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold ml-auto" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("__webhookUrl")) + " : "
                            ),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.formInput.accountWebhookUrl.value)
                                ),
                              ]
                            ),
                            _vm.isSupportClipboardApi()
                              ? _c(
                                  "span",
                                  { on: { click: _vm.onClickCopyWebhookUrl } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-copy cursor-pointer mx-2",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm.formInput.accountType.value == "line"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 py-2 px-4 my-3",
                              attrs: { id: "line-block" },
                            },
                            [
                              _c("label", {}, [
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("__channelSecret_MsgAPI")) +
                                    ": "
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountSecret.value,
                                    expression: "formInput.accountSecret.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountSecret.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountSecret.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountSecret,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("label", {}, [
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("__channelAccessToken_MsgAPI")
                                    ) +
                                    ": "
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountToken.value,
                                    expression: "formInput.accountToken.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountToken.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountToken.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountToken,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.formInput.accountType.value == "wechat"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 py-4 px-4 my-3",
                              attrs: { id: "wechat-block" },
                            },
                            [
                              _vm._m(2),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountAppId.value,
                                    expression: "formInput.accountAppId.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountAppId.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountAppId.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountAppId,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(3),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountSecret.value,
                                    expression: "formInput.accountSecret.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountSecret.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountSecret.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountSecret,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(4),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountToken.value,
                                    expression: "formInput.accountToken.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountToken.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountToken.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountToken,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.formInput.accountType.value == "fb"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 py-2 px-4 my-3",
                              attrs: { id: "line-block" },
                            },
                            [
                              _vm._m(5),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountSecret.value,
                                    expression: "formInput.accountSecret.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountSecret.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountSecret.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountSecret,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(6),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountToken.value,
                                    expression: "formInput.accountToken.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountToken.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountToken.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountToken,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.formInput.accountType.value == "ig"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 py-2 px-4 my-3",
                              attrs: { id: "instagram-block" },
                            },
                            [
                              _vm._m(7),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountSecret.value,
                                    expression: "formInput.accountSecret.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountSecret.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountSecret.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountSecret,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(8),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.accountToken.value,
                                    expression: "formInput.accountToken.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.formInput.accountToken.error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.accountToken.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.accountToken,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c("hr"),
                      _c("div", { staticClass: "col-12 pb-4 my-1" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("__Accounticon")))]),
                        _c(
                          "div",
                          { staticClass: "border" },
                          [
                            _c("vue-dropzone", {
                              ref: "myVueDropzone",
                              attrs: {
                                id: "dropzone",
                                options: _vm.dropzoneOptions,
                              },
                              on: {
                                "vdropzone-success": _vm.showSuccess,
                                "vdropzone-removed-file": _vm.removeFile,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._v(_vm._s(_vm.formInput.errorText)),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "account-create-btn",
                    type: "button",
                    "data-translate": "_send.setting",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createAccount()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("__establishconnection")) + " ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner-border" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" appid: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" appsecret: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" app token: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" app secret: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" app token: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" app secret: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" app token: "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }