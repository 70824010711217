var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "segment",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _vm._t("header", function () {
                  return [_vm._v(_vm._s(_vm.modalHeader))]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("content", function () {
                  return [_vm._v(_vm._s(_vm.modalContent))]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _vm._t("footer", function () {
                  return [_vm._v(_vm._s(_vm.modalFooter))]
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }