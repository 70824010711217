// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Flaticon.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Flaticon.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Flaticon.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Flaticon.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Flaticon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#Flaticon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\t/*\r\n  \tFlaticon icon font: Flaticon\r\n  \tCreation date: 27/09/2020 07:29\r\n  \t*/\r\n\r\n@font-face {\r\n  font-family: \"Flaticon\";\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\");\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n\r\n@media screen and (-webkit-min-device-pixel-ratio:0) {\r\n  @font-face {\r\n    font-family: \"Flaticon\";\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\");\r\n  }\r\n}\r\n\r\n[class^=\"flaticon-\"]:before, [class*=\" flaticon-\"]:before,\r\n[class^=\"flaticon-\"]:after, [class*=\" flaticon-\"]:after {   \r\n  font-family: Flaticon;\r\n        font-size: 12px;\r\nfont-style: normal;\r\nmargin-left: 0px;\r\n}\r\n\r\n.flaticon-algorithm:before { content: \"\\f100\"; }\r\n.flaticon-calendar:before { content: \"\\f101\"; }\r\n\r\n\r\n.glyph .glyph-icon {\r\n\r\n  font-family:\"Flaticon\";\r\n  font-size: 12px;\r\n  line-height: 1;\r\n  display: inline；\r\n}\r\n.glyph .glyph-icon:before {\r\n  font-size: 12px;\r\n  color: #222;\r\n  margin-left: 0;\r\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
