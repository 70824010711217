var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "row" }),
      _c("account-card", {
        attrs: {
          data: _vm.account.data,
          adminId: _vm.adminId,
          jwtToken: _vm.jwtToken,
        },
      }),
      _c("div", { staticClass: "btn-follow" }, [
        _c(
          "a",
          {
            staticStyle: { color: "#fff" },
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.createAccount()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-plus" })]
        ),
      ]),
      _vm.componentKey
        ? _c("create-account", {
            key: _vm.componentKey,
            attrs: {
              adminId: _vm.createAccountData.adminId,
              jwtToken: _vm.createAccountData.jwtToken,
              accountId: _vm.createAccountData.accountId,
              allAccounts: _vm.account.data,
              webhookUrl: _vm.createAccountData.webhookUrl,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }