var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table", class: _vm.tableClass }, [
    _c("thead", { staticClass: "thead-dark" }, [
      _c("th", [_vm._v("#")]),
      _c(
        "th",
        {
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.onClickTagNameColumnHeader },
        },
        [
          _vm._v(
            " " +
              _vm._s(this.$t("__TagName")) +
              " " +
              _vm._s(_vm.getSortOrderLabel(_vm.tagNameColumnSortState)) +
              " "
          ),
        ]
      ),
      _c("th", [_vm._v(_vm._s(this.$t("__TagColor")))]),
      _c("th", [_vm._v("Values")]),
      _c("th"),
    ]),
    _c(
      "tbody",
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "tr",
          { key: index },
          [
            _vm._t(
              "default",
              function () {
                return [
                  _c("td", [_vm._v(_vm._s(index + 1))]),
                  _c("td", [_vm._v(_vm._s(item.name))]),
                  _c(
                    "td",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { align: "center" },
                    },
                    [
                      _c("label", {
                        staticClass: "m-auto color-label",
                        class: item.color,
                      }),
                    ]
                  ),
                  _c("td", [_vm._v(_vm._s(item.values))]),
                  _c("td", { staticStyle: { width: "80px" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "editStoryIcon",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.editTag(item)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-edit mx-1" })]
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteTag(item)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-trash text-danger mx-1",
                        }),
                      ]
                    ),
                  ]),
                ]
              },
              { row: item }
            ),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }