var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border p-2 mb-2" }, [
    _c("label", { attrs: { for: "" } }, [
      _c("b", [_vm._v(_vm._s(this.$t("__userlist_userFirst")))]),
    ]),
    _c("div", [
      _c(
        "a",
        {
          staticClass: "text-danger float-right userlist-delete-icon-a",
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.deleteCondition },
        },
        [
          _c(
            "svg",
            {
              staticClass:
                "svg-inline--fa fa-trash text-danger userlist-delete-icon",
              attrs: {
                "aria-hidden": "true",
                focusable: "false",
                "data-prefix": "fas",
                "data-icon": "trash",
                role: "img",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 448 512",
                "data-fa-i2svg": "",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d: "M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _c("form", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm" },
            [
              _c("label", { attrs: { for: "exampleFormControlInput1" } }, [
                _vm._v(_vm._s(this.$t("__userlist_timeStart"))),
              ]),
              _c("datetime", {
                attrs: {
                  type: "datetime",
                  "minute-step": 5,
                  "input-class": "form-control col-12",
                  "input-style": "width: 16rem;",
                },
                model: {
                  value: _vm.timeStart,
                  callback: function ($$v) {
                    _vm.timeStart = $$v
                  },
                  expression: "timeStart",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm" },
            [
              _c("label", { attrs: { for: "exampleFormControlInput1" } }, [
                _vm._v(_vm._s(this.$t("__userList_timeEnd"))),
              ]),
              _c("datetime", {
                attrs: {
                  type: "datetime",
                  "minute-step": 5,
                  "input-class": "form-control col-12",
                  "input-style": "width: 16rem;",
                },
                model: {
                  value: _vm.timeEnd,
                  callback: function ($$v) {
                    _vm.timeEnd = $$v
                  },
                  expression: "timeEnd",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }