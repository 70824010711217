var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card", attrs: { id: "node-board" } }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("nodes", {
            attrs: {
              adminId: _vm.adminId,
              accountId: _vm.accountId,
              scenarioId: _vm.id,
            },
            on: { saveformData: _vm.getNodesData, newTag: _vm.saveNewTag },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row justify-content-end" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mr-2",
          on: { click: _vm.toggleEditMenu },
        },
        [_vm._v(" " + _vm._s(_vm.$t("__menuSettings")))]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary mr-3",
          on: {
            click: function ($event) {
              return _vm.saveNode()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("__determine")))]
      ),
    ]),
    _vm.isMenuModalOpen
      ? _c("div", { staticClass: "mt-3" }, [
          _c("div", { staticClass: "card p-3" }, [
            _c("h5", [_vm._v(_vm._s(_vm.$t("__editMenuSettings")))]),
            _c(
              "div",
              [
                _c("div", { staticClass: "row mb-2 align-items-center" }, [
                  _c("p", { staticClass: "col-2 my-1" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("__enable")))]),
                  ]),
                  _c("p", { staticClass: "col-3 my-2" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("__menuName")))]),
                  ]),
                  _c("p", { staticClass: "col-5 my-2" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("__menuUrl")))]),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "text-info m-1",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.addMenuEntry()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-plus text-info" })]
                  ),
                ]),
                _vm._l(_vm.menuEntries, function (entry, index) {
                  return _c(
                    "div",
                    { key: "entry-" + index, staticClass: "row mb-1" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-2 my-2 d-flex align-items-center" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: entry.enable,
                                expression: "entry.enable",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "menu-enable-" + index,
                            },
                            domProps: {
                              checked: Array.isArray(entry.enable)
                                ? _vm._i(entry.enable, null) > -1
                                : entry.enable,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = entry.enable,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        entry,
                                        "enable",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        entry,
                                        "enable",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(entry, "enable", $$c)
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "col-3 my-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: entry.name,
                              expression: "entry.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "menu-name-" + index,
                            placeholder: "Enter menu name",
                          },
                          domProps: { value: entry.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(entry, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-5 my-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: entry.url,
                              expression: "entry.url",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "menu-url-" + index,
                            placeholder: "Enter menu URL",
                          },
                          domProps: { value: entry.url },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(entry, "url", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-1 my-2 d-flex align-items-center" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-outline-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteMenuEntry(index)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-trash" })]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }