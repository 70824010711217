var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "createEditStory",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content border border-success" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "p",
                {
                  staticClass: "modal-title text-bold",
                  attrs: { id: "story-create-title" },
                },
                [_vm._v(" " + _vm._s(_vm.formInput.scenarioName) + " ")]
              ),
              _c(
                "span",
                {
                  attrs: { id: "story-create-edit" },
                  on: {
                    click: function ($event) {
                      return _vm.editName()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1",
                  }),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formInput.scenarioName,
                    expression: "formInput.scenarioName",
                  },
                ],
                staticClass: "form-control element-hide",
                attrs: { type: "text", id: "story-create-input" },
                domProps: { value: _vm.formInput.scenarioName },
                on: {
                  keypress: function ($event) {
                    return _vm.confirmChangeName($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formInput, "scenarioName", $event.target.value)
                  },
                },
              }),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-sm-4 col-form-label" }, [
                    _c("label", { attrs: { for: "inputPassword" } }, [
                      _vm._v(_vm._s(_vm.$t("__Triggerpoint"))),
                    ]),
                    _vm._v(": "),
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.selectTrigger,
                            expression: "formInput.selectTrigger",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formInput,
                              "selectTrigger",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", selected: "" } }, [
                          _vm._v(_vm._s(_vm.$t("__Manualsending"))),
                        ]),
                        _c("option", { attrs: { value: "keyword" } }, [
                          _vm._v(_vm._s(_vm.$t("__Keyword"))),
                        ]),
                        _vm.accountType() != "fb" && _vm.accountType() != "ig"
                          ? _c("option", { attrs: { value: "follow" } }, [
                              _vm._v(_vm._s(_vm.$t("__Userjoin"))),
                            ])
                          : _vm._e(),
                        _c("option", { attrs: { value: "liffScenario" } }, [
                          _vm._v(_vm._s(_vm.$t("__LiffScenario"))),
                        ]),
                        _c("option", { attrs: { value: "error" } }, [
                          _vm._v(_vm._s(_vm.$t("__Errormessage"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.formInput.selectTrigger == "keyword"
                  ? _c(
                      "div",
                      {
                        staticClass: "col-12 card px-3 py-3",
                        attrs: { id: "keyword-block" },
                      },
                      [
                        _c("div", { staticClass: "row mb-3 px-2" }, [
                          _c("input", {
                            ref: "addKeyword",
                            staticClass: "form-control col-9",
                            attrs: {
                              type: "text",
                              id: "keyword-value",
                              placeholder: this.$t("__keywordInput"),
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-secondary col-2 mx-2",
                              attrs: {
                                id: "add-keyword",
                                "data-translate": "_create.edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addKeyword(_vm.$refs.addKeyword)
                                },
                              },
                            },
                            [_vm._v(" ENTER ")]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-12 border py-3",
                            staticStyle: {
                              "overflow-y": "scroll",
                              "max-height": "13rem",
                            },
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "table table-hover",
                                attrs: { id: "keyword-table" },
                              },
                              _vm._l(
                                _vm.formInput.keywords,
                                function (item, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                        },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-danger float-right",
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteKeyword(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-trash text-danger mx-1",
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "button",
                    id: "submit-story-btn",
                    "data-translate": "_create.segment",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitScenario()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(this.modalData.modalBtn) + " ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }