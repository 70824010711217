var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "segment",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-xl modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "p",
                {
                  staticClass: "modal-title text-bold",
                  attrs: { id: "segment-create-title" },
                },
                [_vm._v(" " + _vm._s(_vm.formInput.segmentName) + " ")]
              ),
              _c(
                "span",
                {
                  attrs: { id: "segment-create-edit" },
                  on: {
                    click: function ($event) {
                      return _vm.editName()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1",
                  }),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formInput.segmentName,
                    expression: "formInput.segmentName",
                  },
                ],
                staticClass: "form-control element-hide",
                attrs: { type: "text", id: "segment-create-input" },
                domProps: { value: _vm.formInput.segmentName },
                on: {
                  keypress: function ($event) {
                    return _vm.confirmChangeName($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formInput, "segmentName", $event.target.value)
                  },
                },
              }),
              _c("input", { attrs: { type: "hidden", id: "current-number" } }),
              _c("input", { attrs: { type: "hidden", id: "segment-target" } }),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _vm.isLoading
                ? _c("div", { staticClass: "text-center" }, [_vm._m(1)])
                : _vm._e(),
              !_vm.isLoading
                ? _c("div", { staticClass: "container-fluid" }, [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                      _vm._v(" " + _vm._s(_vm.$t("__requiredItem")) + " "),
                      _c("label", { staticClass: "ml-auto" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formInput.defaultUsersSegment,
                              expression: "formInput.defaultUsersSegment",
                            },
                          ],
                          staticClass: "ml-2",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.formInput.defaultUsersSegment
                            )
                              ? _vm._i(
                                  _vm.formInput.defaultUsersSegment,
                                  null
                                ) > -1
                              : _vm.formInput.defaultUsersSegment,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.formInput.defaultUsersSegment,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formInput,
                                      "defaultUsersSegment",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formInput,
                                      "defaultUsersSegment",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.formInput,
                                  "defaultUsersSegment",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("__DefaultUserSegment"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectRule,
                              expression: "selectRule",
                            },
                          ],
                          staticClass: "form-control segment-rule",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectRule = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "users",
                                "data-translate": "_customize.segment",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("__Customgroup")) + " "
                              ),
                            ]
                          ),
                          _vm.adminId == "0001"
                            ? _c(
                                "option",
                                {
                                  attrs: {
                                    value: "cvParam",
                                    "data-translate": "_segmentByCv.segment",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("__CvParam")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "conditions",
                                "data-translate": "_segmentByCondition.segment",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("__MultiCondition")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm.selectRule === "conditions"
                      ? _c("div", { attrs: { id: "segment-block" } }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  "data-translate": "_chooseTag.segment",
                                },
                              },
                              [
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("__Selectcategoryconditions"))
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "mb-3 py-1",
                              staticStyle: {
                                "overflow-y": "scroll",
                                "max-height": "28rem",
                                "min-height": "12rem",
                              },
                            },
                            [
                              _vm._l(
                                _vm.formInput.segmentConditions,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "border border-dark container mb-3 py-2",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-danger pull-right",
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteCondition(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-trash text-danger",
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "clearfix" }),
                                      _vm._l(
                                        item.multi_condition,
                                        function (subItem, subIndex) {
                                          return _c("div", { key: subIndex }, [
                                            _c(
                                              "div",
                                              { staticClass: "form-row pt-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-2",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("__source")
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              subItem.source,
                                                            expression:
                                                              "subItem.source",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                subItem,
                                                                "source",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.onCondSourceChanged(
                                                                $event,
                                                                index,
                                                                subIndex
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "tag",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__label"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value:
                                                                "uservariable",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__variable"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value:
                                                                "followtime",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__FollowAccountTime"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "cvkey",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__CvParam"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value:
                                                                "serverpushtime",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__ServerPushTime"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "utils",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__metaUtils"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "cros",
                                                            },
                                                          },
                                                          [_vm._v("CROS")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-2",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("__Condition")
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              subItem.expression,
                                                            expression:
                                                              "subItem.expression",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          "aria-placeholder":
                                                            _vm.$t(
                                                              "__fontcolor"
                                                            ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              subItem,
                                                              "expression",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cros")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "exactly_match",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__ExactlyMatch"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cros")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "fuzzy_match",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__FuzzyMatch"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cvkey")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "has_value",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__segmentcondition"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cvkey")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "no_value",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__segmentnocondition"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        subItem.source ==
                                                        "cvkey"
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "no_exist",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__segmentnoexist"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cros")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "num_range",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__NumberRange"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cros")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "num_gt",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__GreaterThanNumber"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source ==
                                                          "tag") |
                                                        (subItem.source ==
                                                          "uservariable") |
                                                        (subItem.source ==
                                                          "cros")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "num_lt",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__LessThanNumber"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        (subItem.source !=
                                                          "serverpushtime") &
                                                        (subItem.source !=
                                                          "utils")
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "date_range",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__DataRange"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        subItem.source ==
                                                        "serverpushtime"
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "datetime_range",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__DatatimeRange"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        subItem.source ==
                                                        "utils"
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "short_link",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__shortLink"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        subItem.source ==
                                                        "utils"
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "auto_reply_comments",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__autoReplyComments"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        subItem.source ==
                                                        "utils"
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "auto_reply_live_mention",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__autoReplyLiveMention"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        subItem.source ==
                                                        "utils"
                                                          ? _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "auto_reply_media",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__autoReplyMedia"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                subItem.source == "tag"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__label")
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem.tag,
                                                                expression:
                                                                  "subItem.tag",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal =
                                                                    Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function (
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function (
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                  _vm.$set(
                                                                    subItem,
                                                                    "tag",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onCondTagChanged(
                                                                    index,
                                                                    subIndex
                                                                  )
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__TagSelectOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.tagList,
                                                              function (
                                                                tag,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      100 +
                                                                      index,
                                                                    domProps: {
                                                                      value:
                                                                        tag,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          tag.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "tag" &&
                                                subItem.expression ==
                                                  "exactly_match"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__value")
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem.value,
                                                                expression:
                                                                  "subItem.value",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              "aria-placeholder":
                                                                _vm.$t(
                                                                  "__fontcolor"
                                                                ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  subItem,
                                                                  "value",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__TagValueSelectOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.valuesOfTag(
                                                                subItem.tag
                                                                  .color,
                                                                subItem.tag.name
                                                              ),
                                                              function (
                                                                value,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      100 +
                                                                      index,
                                                                    domProps: {
                                                                      value:
                                                                        value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          value
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "uservariable"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__variable"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem
                                                                    .uservariable
                                                                    .name,
                                                                expression:
                                                                  "subItem.uservariable.name",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  subItem.uservariable,
                                                                  "name",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__UserVariableSelectOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.allVariables,
                                                              function (
                                                                value,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      "variable-" +
                                                                      index,
                                                                    domProps: {
                                                                      value:
                                                                        value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          value
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "cros"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("類型"),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem.cros
                                                                    .type,
                                                                expression:
                                                                  "subItem.cros.type",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  subItem.cros,
                                                                  "type",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__CrosTypeOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "cros_latest",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次紀錄"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "cros_history",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "歷史紀錄"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "cros" &&
                                                subItem.cros.type ==
                                                  "cros_latest"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-4",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("CROS"),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem.cros
                                                                    .item,
                                                                expression:
                                                                  "subItem.cros.item",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  subItem.cros,
                                                                  "item",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__CrosOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "line_registered_date",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "加入好友日"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "birth",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "出生年月日"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "latest_order_id",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次購買商品ID"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "create_date_latest",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次購買日期"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "actual_shipping_date_latest",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次發貨日期"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "sum",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次購買總金額"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "count",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "購買次數"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "teiki_flg",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "定期購買者"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "promotion_code",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次促銷代碼"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "product_code",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "最近一次商品代碼"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "regular_purchase_flg",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "定期標誌"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "current_delivery_number_of_time",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "定期次數"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "cros" &&
                                                subItem.cros.type ==
                                                  "cros_history"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-4",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("CROS"),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem.cros
                                                                    .item,
                                                                expression:
                                                                  "subItem.cros.item",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  subItem.cros,
                                                                  "item",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__CrosOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "order_id",
                                                                },
                                                              },
                                                              [_vm._v("訂單ID")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "promotion_code",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "促銷代碼"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "create_date",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "訂單創建日期"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "actual_shipping_date",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "發貨日期"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "stock_warehousing_date",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "退貨日期"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "status",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "訂單狀態"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "payment_total",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "訂單金額"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "product_code",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "訂單明細:商品代碼"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "price",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "訂單明細:商品代碼金額"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "quantity",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "訂單明細:商品代碼數量"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                (subItem.expression ==
                                                  "exactly_match") &
                                                  (subItem.source ==
                                                    "uservariable" ||
                                                    subItem.source == "cros") ||
                                                subItem.expression ==
                                                  "fuzzy_match" ||
                                                subItem.expression ==
                                                  "num_gt" ||
                                                subItem.expression == "num_lt"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__value")
                                                            )
                                                          ),
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                subItem.value,
                                                              expression:
                                                                "subItem.value",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              subItem.value,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                subItem,
                                                                "value",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "cvkey" &&
                                                subItem.expression !==
                                                  "no_exist"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__SelectCvParam"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  subItem.value,
                                                                expression:
                                                                  "subItem.value",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  subItem,
                                                                  "value",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__CvParam"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "orderId",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "orderId"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "cvdate",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [_vm._v("cvdate")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "from_server_push",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "from_server_push"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.source == "utils"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__utilsId"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                subItem.value,
                                                              expression:
                                                                "subItem.value",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              subItem.value,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                subItem,
                                                                "value",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.expression ==
                                                "date_range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-2",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__timepickerStartTime"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("datetime", {
                                                          attrs: {
                                                            type: "date",
                                                            "input-class":
                                                              "form-control",
                                                          },
                                                          model: {
                                                            value:
                                                              subItem.range
                                                                .start,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                subItem.range,
                                                                "start",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subItem.range.start",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                subItem.expression ==
                                                "date_range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-2",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__timepickerEndTime"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("datetime", {
                                                          attrs: {
                                                            type: "date",
                                                            "input-class":
                                                              "form-control",
                                                          },
                                                          model: {
                                                            value:
                                                              subItem.range.end,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                subItem.range,
                                                                "end",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subItem.range.end",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                subItem.expression ==
                                                "datetime_range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__timepickerStartTime"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("datetime", {
                                                          attrs: {
                                                            type: "datetime",
                                                            "input-class":
                                                              "form-control",
                                                          },
                                                          model: {
                                                            value:
                                                              subItem.range
                                                                .start,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                subItem.range,
                                                                "start",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subItem.range.start",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                subItem.expression ==
                                                "datetime_range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__timepickerEndTime"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("datetime", {
                                                          attrs: {
                                                            type: "datetime",
                                                            "input-class":
                                                              "form-control",
                                                          },
                                                          model: {
                                                            value:
                                                              subItem.range.end,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                subItem.range,
                                                                "end",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subItem.range.end",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                subItem.expression ==
                                                "num_range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-2",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__Start")
                                                            )
                                                          ),
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                subItem.range
                                                                  .start,
                                                              expression:
                                                                "subItem.range.start",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              subItem.range
                                                                .start,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                subItem.range,
                                                                "start",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                subItem.expression ==
                                                "num_range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-2",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__End")
                                                            )
                                                          ),
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                subItem.range
                                                                  .end,
                                                              expression:
                                                                "subItem.range.end",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              subItem.range.end,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                subItem.range,
                                                                "end",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.multi_condition
                                                        .length > 1,
                                                    expression:
                                                      "item.multi_condition.length > 1",
                                                  },
                                                ],
                                                staticClass: "pull-right",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-danger m-2",
                                                    attrs: {
                                                      href: "javascript:void(0)",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteSubCondition(
                                                          index,
                                                          subIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-minus text-danger",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      subIndex ==
                                                      item.multi_condition
                                                        .length -
                                                        1,
                                                    expression:
                                                      "subIndex == item.multi_condition.length - 1",
                                                  },
                                                ],
                                                staticClass: "pull-right",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-info m-2",
                                                    attrs: {
                                                      href: "javascript:void(0)",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addSubCondition(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-plus text-info",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("hr", { staticClass: "solid" }),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row pt-4 add-btn-input cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addNewCondition()
                                    },
                                  },
                                },
                                [_vm._m(2)]
                              ),
                              _vm.tagList.length == 0
                                ? _c("p", { staticClass: "m-auto py-4" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("__Nolabelsavailable")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectRule === "users"
                      ? _c("div", { attrs: { id: "group-block" } }, [
                          _c("div", { staticClass: "row mt-4" }, [
                            _c("div", { staticClass: "mt-auto" }, [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    "data-translate": "_chooseUser.segment",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.$t("__Selectuser"))),
                                ]
                              ),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.allSelected,
                                      expression: "allSelected",
                                    },
                                  ],
                                  staticClass: "ml-2",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.allSelected)
                                      ? _vm._i(_vm.allSelected, null) > -1
                                      : _vm.allSelected,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.allSelected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.allSelected = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.allSelected = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.allSelected = $$c
                                        }
                                      },
                                      function ($event) {
                                        return _vm.selectAllChanged($event)
                                      },
                                    ],
                                  },
                                }),
                                _vm._v(" All"),
                              ]),
                            ]),
                            _c("div", { staticClass: "ml-auto py-2 pr-0" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchUser,
                                    expression: "searchUser",
                                  },
                                ],
                                staticClass: "search-box",
                                staticStyle: { width: "500px" },
                                attrs: {
                                  id: "segment-search-user",
                                  type: "text",
                                  name: "q",
                                },
                                domProps: { value: _vm.searchUser },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchUsers.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.searchUser = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  attrs: { for: "segment-search-user" },
                                  on: { click: _vm.searchUsers },
                                },
                                [
                                  _c("span", {
                                    staticClass: "fas fa-search search-icon",
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "row border mb-3 py-2",
                              staticStyle: {
                                "overflow-y": "scroll",
                                "max-height": "20rem",
                                "min-height": "12rem",
                              },
                            },
                            [
                              _vm._l(_vm.userList, function (user, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "input-group mb-2 col-4",
                                    staticStyle: { "max-height": "3rem" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-prepend" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput
                                                      .checkedUserIds,
                                                  expression:
                                                    "formInput.checkedUserIds",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "user" + index,
                                              },
                                              domProps: {
                                                value: user.userId,
                                                checked: Array.isArray(
                                                  _vm.formInput.checkedUserIds
                                                )
                                                  ? _vm._i(
                                                      _vm.formInput
                                                        .checkedUserIds,
                                                      user.userId
                                                    ) > -1
                                                  : _vm.formInput
                                                      .checkedUserIds,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.formInput
                                                        .checkedUserIds,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = user.userId,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.formInput,
                                                          "checkedUserIds",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.formInput,
                                                          "checkedUserIds",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "checkedUserIds",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "py-2 border rounded col",
                                        staticStyle: {
                                          "max-height": "3rem",
                                          "max-width": "23rem",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: user.userPicture,
                                            width: "30",
                                            height: "30",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "px-2",
                                            staticStyle: {
                                              "max-width": "21rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.hideNameLength(
                                                  user.userName
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm.userList.length == 0
                                ? _c("p", { staticClass: "m-auto py-4" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("__Nousersfound")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectRule === "cvParam"
                      ? _c("div", { attrs: { id: "group-block" } }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  "data-translate": "_segmentByCv.segment",
                                },
                              },
                              [
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("__SelectCvParam"))),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "row mt-2 py-2",
                              attrs: { id: "list-button-area" },
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formInput.cvParam.key,
                                      expression: "formInput.cvParam.key",
                                    },
                                  ],
                                  staticClass: "col-3 form-control ml-2",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.formInput.cvParam,
                                        "key",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "orderId", selected: "" },
                                    },
                                    [_vm._v("orderId")]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.cvParam.value,
                                    expression: "formInput.cvParam.value",
                                  },
                                ],
                                staticClass: "col-8 form-control ml-2",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.formInput.cvParam.value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formInput.cvParam,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "button",
                    "data-translate": "_create.segment",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitSegment()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.modalData.modalBtn) + " ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner-border" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }