var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table", class: _vm.tableClass },
    [
      _c("thead", { staticClass: "thead-dark" }, [
        _c("th", [_vm._v(_vm._s(_vm.$t("__utilsName")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("__utilsType")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("__utilsStatus")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("__workTime")))]),
        _c("th"),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "tr",
            { key: index },
            [
              _vm._t(
                "default",
                function () {
                  return [
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c("td", [
                      item.type === "auto_reply_comments"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("__autoReplyComments"))),
                          ])
                        : _vm._e(),
                      item.type === "short_link"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("__shortLink")))])
                        : _vm._e(),
                      item.type === "auto_reply_live_mention"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("__autoReplyLiveMention"))),
                          ])
                        : _vm._e(),
                      item.type === "auto_reply_media"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("__autoReplyMedia"))),
                          ])
                        : _vm._e(),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "togglediv" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.enable,
                              expression: "item.enable",
                            },
                          ],
                          attrs: { type: "checkbox", id: "switch_" + index },
                          domProps: {
                            checked: Array.isArray(item.enable)
                              ? _vm._i(item.enable, null) > -1
                              : item.enable,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = item.enable,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        item,
                                        "enable",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        item,
                                        "enable",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(item, "enable", $$c)
                                }
                              },
                              function ($event) {
                                return _vm.handleEnableChange(item, $event)
                              },
                            ],
                          },
                        }),
                        _c("label", {
                          staticClass: "toggle",
                          attrs: { for: "switch_" + index },
                        }),
                      ]),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.toDateString(item.range.start)) +
                          " ~ " +
                          _vm._s(_vm.toDateString(item.range.end))
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "px-1",
                          attrs: {
                            href: "javascript:void(0)",
                            "data-toggle": "modal",
                            "data-target": "#util",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editUtil(item)
                            },
                          },
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
                        1
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.settingUtil(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-comment-dots mx-1" })]
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.copyUtil(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-copy mx-1" })]
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteUtil(item.id)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-trash text-danger mx-1",
                          }),
                        ]
                      ),
                    ]),
                  ]
                },
                { row: item }
              ),
            ],
            2
          )
        }),
        0
      ),
      _vm.createMetaUtilModalKey
        ? _c("create-meta-util-modal", {
            key: _vm.createMetaUtilModalKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              utiloptionData: _vm.utilsType,
              accountId: _vm.accountId,
              modalData: _vm.modalData,
              editId: _vm.editId,
              editKey: _vm.isEdit,
              utilsList: _vm.data,
              objectKey: _vm.createMetaUtilModalKey,
            },
          })
        : _vm._e(),
      _vm.autoReplyCommentsModalKey
        ? _c("auto-reply-comments-modal", {
            key: _vm.autoReplyCommentsModalKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              modalData: _vm.modalData,
              editId: _vm.editId,
              allScenario: _vm.allScenario,
              igAccountId: _vm.igAccountId,
              appToken: _vm.appToken,
              igPost: _vm.igPost,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "btn-follow" }, [
        _c(
          "a",
          {
            staticStyle: { color: "#fff" },
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.createUtil()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-plus" })]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }