var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { staticClass: "mb-3" }, [
      _c("div", { staticClass: "card-header" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "ml-auto py-2 pr-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "search-box",
            attrs: { id: "search-box", type: "text", name: "q" },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
          _c("label", { attrs: { for: "search-box" } }, [
            _c("span", { staticClass: "fas fa-search search-icon" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "text-center" }, [
              _c("div", { staticClass: "spinner-border" }, [
                _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
              ]),
            ])
          : _vm._e(),
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c("story-table", {
                  attrs: {
                    type: "hover table-bordered",
                    adminId: _vm.adminId,
                    accountId: _vm.accountId,
                    allScenario: _vm.allScenario,
                    userIconList: _vm.userIconList,
                    data: _vm.table.data,
                    columns: _vm.table.columns,
                    trigger: _vm.exsitTrigger,
                    currentNumber: _vm.allScenario.length,
                  },
                }),
                _c("pagination", {
                  attrs: {
                    "per-page": _vm.rowPerPage,
                    records: _vm.allScenario.length,
                    options: {
                      texts: {
                        count: this.$t("__paginationText"),
                      },
                    },
                  },
                  on: { paginate: _vm.callback },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }