var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c(
        "div",
        { staticClass: "col-12 my-2" },
        [
          _vm._l(_vm.appsettings, function (appsettings, aindex) {
            return _c("div", { key: aindex }, [
              _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
                _c(
                  "div",
                  {
                    staticClass: "badge badge-primary text-wrap",
                    staticStyle: { width: "2rem" },
                  },
                  [_vm._v(" NO " + _vm._s(aindex + 1) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-danger float-right",
                    staticStyle: {
                      padding: "2px",
                      "padding-right": "8px",
                      "padding-left": "8px",
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteappsettings(aindex)
                      },
                    },
                  },
                  [_vm._v("Delete")]
                ),
                _c("div", { staticClass: "row mt-4 my-2" }, [
                  _c("div", { staticClass: "col-3 my-2" }, [
                    _vm._v(_vm._s(_vm.$t("__Target"))),
                  ]),
                  _c("div", { staticClass: "col-8 my-2" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: appsettings.target,
                            expression: "appsettings.target",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              appsettings,
                              "target",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.allTarget, function (value, index) {
                        return _c(
                          "option",
                          {
                            key: "target-" + index,
                            domProps: { value: value },
                          },
                          [_vm._v(" " + _vm._s(value) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "row mt-4 my-2" }, [
                  _c("div", { staticClass: "col-3 my-2" }, [_vm._v("App ID")]),
                  _c("div", { staticClass: "row col-9 my-2" }, [
                    _c(
                      "p",
                      {
                        staticClass: "mb-2 col-10",
                        attrs: { id: "Id-" + aindex + "-title" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(appsettings.id ? appsettings.id : "") +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: { id: "Id-" + aindex + "-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.editUI("Id-" + aindex)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-pencil-alt cursor-pointer pl-1",
                        }),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: appsettings.id,
                          expression: "appsettings.id",
                        },
                      ],
                      staticClass: "form-control mb-2 col-10 element-hide",
                      attrs: { type: "text", id: "Id-" + aindex + "-input" },
                      domProps: { value: appsettings.id },
                      on: {
                        keypress: function ($event) {
                          return _vm.confirmUIChangeKey($event, "Id-" + aindex)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(appsettings, "id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "row mt-4 my-1" }, [
                  _c("div", { staticClass: "col-3 my-1" }, [
                    _c("h5", [_vm._v("config")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 my-1" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }, [
                      _vm._v(_vm._s(_vm.$t("__webhookUrl"))),
                    ]),
                    _c("div", { staticClass: "row col-9" }, [
                      _c(
                        "p",
                        {
                          staticClass: "mb-2 col-10",
                          attrs: { id: "webhook-" + aindex + "-title" },
                        },
                        [_vm._v(" " + _vm._s(appsettings.config.webhook) + " ")]
                      ),
                      _c(
                        "span",
                        {
                          attrs: { id: "webhook-" + aindex + "-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editUI("webhook-" + aindex)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-pencil-alt cursor-pointer pl-1",
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: appsettings.config.webhook,
                            expression: "appsettings.config.webhook",
                          },
                        ],
                        staticClass: "form-control mb-2 col-10 element-hide",
                        attrs: {
                          type: "text",
                          id: "webhook-" + aindex + "-input",
                        },
                        domProps: { value: appsettings.config.webhook },
                        on: {
                          keypress: function ($event) {
                            return _vm.confirmUIChangeKey(
                              $event,
                              "webhook-" + aindex
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              appsettings.config,
                              "webhook",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ])
          }),
          _c(
            "div",
            {
              staticClass: "row pt-4 add-btn-input cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.addappsettings()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "mx-auto my-3",
                  attrs: { href: "javascript:void(0)" },
                },
                [_c("i", { staticClass: "fas fa-plus" })]
              ),
            ]
          ),
        ],
        2
      ),
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn-outline-success pull-right",
        attrs: { "data-translate": "" },
        on: {
          click: function ($event) {
            return _vm.updateAccountAppsConfig()
          },
        },
      },
      [_vm._v(" Update ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }