var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card", attrs: { id: "node-board" } }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("nodes", {
            attrs: {
              adminId: _vm.adminId,
              accountId: _vm.accountId,
              scenarioId: _vm.id,
            },
            on: { saveformData: _vm.getNodesData, newTag: _vm.saveNewTag },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary ml-auto mr-3",
          on: {
            click: function ($event) {
              return _vm.saveNode()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("__determine")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }