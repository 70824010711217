import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=281d3a92"
import script from "./Content.vue?vue&type=script&lang=js"
export * from "./Content.vue?vue&type=script&lang=js"
import style0 from "./Content.vue?vue&type=style&index=0&id=281d3a92&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\projects\\02_chatAnnie\\git\\chatbot_new\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('281d3a92')) {
      api.createRecord('281d3a92', component.options)
    } else {
      api.reload('281d3a92', component.options)
    }
    module.hot.accept("./Content.vue?vue&type=template&id=281d3a92", function () {
      api.rerender('281d3a92', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/dashboard/Content.vue"
export default component.exports