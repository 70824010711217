var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group", class: { "input-group": _vm.hasIcon } },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("label", { staticClass: "control-label" }, [
                _vm._v(" " + _vm._s(_vm.label) + " "),
              ])
            : _vm._e(),
        ]
      }),
      _vm._t("addonLeft", function () {
        return [
          _vm.addonLeftIcon
            ? _c("span", { staticClass: "input-group-prepend" }, [
                _c("i", {
                  staticClass: "input-group-text",
                  class: _vm.addonLeftIcon,
                }),
              ])
            : _vm._e(),
        ]
      }),
      _c(
        "input",
        _vm._b(
          {
            staticClass: "form-control",
            attrs: { "aria-describedby": "addon-right addon-left" },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._t("default"),
      _vm._t("addonRight", function () {
        return [
          _vm.addonRightIcon
            ? _c("span", { staticClass: "input-group-append" }, [
                _c("i", {
                  staticClass: "input-group-text",
                  class: _vm.addonRightIcon,
                }),
              ])
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }