var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    _vm._b(
      {
        tag: "component",
        staticClass: "nav-item",
        attrs: { tag: "li" },
        nativeOn: {
          click: function ($event) {
            return _vm.hideSidebar.apply(null, arguments)
          },
        },
      },
      "component",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "a",
        { staticClass: "nav-link" },
        [
          _vm._t("default", function () {
            return [
              _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
              _c("p", [_vm._v(_vm._s(_vm.name))]),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }