var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "existTagModalRef",
      staticClass: "modal fade",
      attrs: {
        id: "tag",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "p",
                {
                  staticClass: "modal-title text-bold",
                  attrs: {
                    id: "exampleModalCenterTitle",
                    "data-translate": "_tag.edit",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("__label")) + " ")]
              ),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "ml-auto py-2 pr-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTag,
                            expression: "searchTag",
                          },
                        ],
                        staticClass: "search-box",
                        attrs: {
                          id: "segment-search-tag",
                          type: "text",
                          name: "q",
                        },
                        domProps: { value: _vm.searchTag },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchTag = $event.target.value
                          },
                        },
                      }),
                      _vm._m(1),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-sm",
                          attrs: {
                            "data-dismiss": "modal",
                            "data-toggle": "modal",
                            "data-target": "#create-tag-modal",
                            "data-translate": "_createNewTag.edit",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("__Addlabel")) + " ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row border mb-3 py-2 px-3",
                      staticStyle: {
                        "overflow-y": "scroll",
                        "max-height": "15rem",
                      },
                    },
                    [
                      _vm._l(_vm.tags, function (tag, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "input-group mb-1" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("div", { staticClass: "input-group-text" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkedTags,
                                      expression: "checkedTags",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "tag" + index,
                                  },
                                  domProps: {
                                    value: tag,
                                    checked: Array.isArray(_vm.checkedTags)
                                      ? _vm._i(_vm.checkedTags, tag) > -1
                                      : _vm.checkedTags,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.checkedTags,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = tag,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.checkedTags = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.checkedTags = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.checkedTags = $$c
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "py-2 border rounded col" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag",
                                    class: tag.color,
                                    attrs: { href: "javascript:void(0)" },
                                  },
                                  [_vm._v(_vm._s(tag.name))]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm.tags.length == 0
                        ? _c("p", { staticClass: "m-auto py-4" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("__Nolabelsavailable")) + " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: {
                    click: function ($event) {
                      return _vm.addExistTag()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("__determine")) + " ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "segment-search-tag" } }, [
      _c("span", { staticClass: "fas fa-search search-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }