var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table", class: _vm.tableClass },
    [
      _c("thead", { staticClass: "thead-dark" }, [
        _c("th", [_vm._v("#")]),
        _c(
          "th",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.onClickSegmentNameColumnHeader },
          },
          [
            _vm._v(
              " " +
                _vm._s(this.$t("__CategoryName")) +
                " " +
                _vm._s(_vm.getSortOrderLabel(_vm.segmentNameColumnSortState)) +
                " "
            ),
          ]
        ),
        _c("th", [_vm._v(_vm._s(this.$t("__Classificationrules")))]),
        _c("th", [_vm._v(_vm._s(this.$t("__Numberofpeopleincategory")))]),
        _c("th", [_vm._v(_vm._s(this.$t("__Sendconversation")))]),
        _c("th"),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "tr",
            { key: index, class: { "table-info": item.defaultUsersSegment } },
            [
              _vm._t(
                "default",
                function () {
                  return [
                    _c("td", [_vm._v(_vm._s(index + 1))]),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c("td", [
                      item.conditions.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              item.conditions,
                              function (condition, condition_index) {
                                return _c(
                                  "div",
                                  {
                                    key: "tag_" + condition_index,
                                    staticClass: "mb-1",
                                  },
                                  _vm._l(
                                    condition.multi_condition,
                                    function (subItem, subIndex) {
                                      return _c(
                                        "ul",
                                        {
                                          key: "cond_" + subIndex,
                                          staticClass:
                                            "list-group list-group-horizontal",
                                        },
                                        [
                                          _c(
                                            "li",
                                            {
                                              staticClass: "list-group-item",
                                              staticStyle: { width: "150px" },
                                            },
                                            [
                                              subItem.source == "uservariable"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__variable")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.source == "tag"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("__label"))
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.source == "followtime"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__FollowAccountTime"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.source == "cvkey"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__CvParam")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.source == "serverpushtime"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__ServerPushTime"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.source == "cros"
                                                ? _c("span", [_vm._v("CROS")])
                                                : _vm._e(),
                                              subItem.source == "utils"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__metaUtils")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                          subItem.source != "utils"
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "list-group-item",
                                                  staticStyle: {
                                                    width: "160px",
                                                  },
                                                },
                                                [
                                                  subItem.source ==
                                                  "uservariable"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            subItem.uservariable
                                                              .name
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "tag"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            subItem.tag.name
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cvkey"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "line_registered_date"
                                                    ? _c("span", [
                                                        _vm._v("加入好友日"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item == "birth"
                                                    ? _c("span", [
                                                        _vm._v("出生年月日"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "latest_order_id"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "最近一次購買商品ID"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "create_date_latest"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "最近一次購買日期"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "actual_shipping_date_latest"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "最近一次發貨日期"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item == "sum"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "最近一次購買總金額"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item == "count"
                                                    ? _c("span", [
                                                        _vm._v("購買次數"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "teiki_flg"
                                                    ? _c("span", [
                                                        _vm._v("定期購買者"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "promotion_code"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "最近一次促銷代碼"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "product_code"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "最近一次商品代碼"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "regular_purchase_flg"
                                                    ? _c("span", [
                                                        _vm._v("定期標誌"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_latest" &&
                                                  subItem.cros.item ==
                                                    "current_delivery_number_of_time"
                                                    ? _c("span", [
                                                        _vm._v("定期次數"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "order_id"
                                                    ? _c("span", [
                                                        _vm._v("訂單ID"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "promotion_code"
                                                    ? _c("span", [
                                                        _vm._v("促銷代碼"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "create_date"
                                                    ? _c("span", [
                                                        _vm._v("訂單創建日期"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "actual_shipping_date"
                                                    ? _c("span", [
                                                        _vm._v("發貨日期"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "stock_warehousing_date"
                                                    ? _c("span", [
                                                        _vm._v("退貨日期"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item == "status"
                                                    ? _c("span", [
                                                        _vm._v("訂單狀態"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "payment_total"
                                                    ? _c("span", [
                                                        _vm._v("訂單金額"),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "product_code"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "訂單明細:商品代碼"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item == "price"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "訂單明細:商品代碼金額"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.source == "cros" &&
                                                  subItem.cros.type ==
                                                    "cros_history" &&
                                                  subItem.cros.item ==
                                                    "quantity"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "訂單明細:商品代碼數量"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "li",
                                            {
                                              staticClass: "list-group-item",
                                              staticStyle: { width: "100px" },
                                            },
                                            [
                                              subItem.expression ==
                                              "exactly_match"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__ExactlyMatch")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression ==
                                              "fuzzy_match"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__FuzzyMatch")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "has_value"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__segmentcondition"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "no_value"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__segmentnocondition"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "no_exist"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__segmentnoexist"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "num_range"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__NumberRange")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "num_gt"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__GreaterThanNumber"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "num_lt"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__LessThanNumber"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "date_range"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__DataRange")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression ==
                                              "datetime_range"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__DatatimeRange"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression == "short_link"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__shortLink")
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression ==
                                              "auto_reply_comments"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__autoReplyComments"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression ==
                                              "auto_reply_live_mention"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__autoReplyLiveMention"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              subItem.expression ==
                                              "auto_reply_media"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__autoReplyMedia"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                          (subItem.expression != "has_value") &
                                          (subItem.expression != "no_value") &
                                          (subItem.expression != "no_exist")
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "list-group-item",
                                                  staticStyle: {
                                                    width: "240px",
                                                  },
                                                },
                                                [
                                                  subItem.expression ==
                                                  "exactly_match"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "fuzzy_match"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression == "num_gt"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression == "num_lt"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "num_range"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            subItem.range.start
                                                          ) +
                                                            " ~ " +
                                                            _vm._s(
                                                              subItem.range.end
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "date_range"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toDateString(
                                                              subItem.range
                                                                .start
                                                            )
                                                          ) +
                                                            " ~ " +
                                                            _vm._s(
                                                              _vm.toDateString(
                                                                subItem.range
                                                                  .end
                                                              )
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "datetime_range"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toDatetimeString(
                                                              subItem.range
                                                                .start
                                                            )
                                                          ) +
                                                            " ~ " +
                                                            _vm._s(
                                                              _vm.toDatetimeString(
                                                                subItem.range
                                                                  .end
                                                              )
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "short_link"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "auto_reply_comments"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "auto_reply_live_mention"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  subItem.expression ==
                                                  "auto_reply_media"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(subItem.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              }
                            ),
                            0
                          )
                        : item.mode == "CVKey"
                        ? _c("div", [
                            _c("span", [
                              _vm._v("orderId: " + _vm._s(item.cvKey.orderId)),
                            ]),
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("__Custom")))]),
                    ]),
                    _c(
                      "td",
                      {
                        class: { pointer: item.users.length != 0 },
                        on: {
                          click: function ($event) {
                            return _vm.showUser(item.users)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.usersCount))]
                    ),
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.broadcastInSegment(item)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "bullhorn" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "px-1",
                          attrs: {
                            href: "javascript:void(0)",
                            "data-toggle": "modal",
                            "data-target": "#segment",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editSegment(item)
                            },
                          },
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "px-1",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.showLiffQrCode(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-qrcode" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "px-1",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSegment(item.id)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-trash text-danger" })]
                      ),
                    ]),
                  ]
                },
                { row: item }
              ),
            ],
            2
          )
        }),
        0
      ),
      _vm.componentKey
        ? _c("create-segment-modal", {
            key: _vm.componentKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              modalData: _vm.modalData,
              editId: _vm.editId,
            },
          })
        : _vm._e(),
      _vm.pushMessageKey
        ? _c("create-push-modal", {
            key: _vm.pushMessageKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              modalData: _vm.broadcastData,
            },
          })
        : _vm._e(),
      _c("qr-code-modal", {
        ref: "QrCodeModal",
        attrs: {
          modalData: _vm.liffModalData,
          allScenario: _vm.allScenario,
          updateSegment: _vm.updateSegmentScenario,
        },
      }),
      _c(
        "div",
        {
          staticClass: "btn-follow",
          on: {
            click: function ($event) {
              return _vm.createSegment()
            },
          },
        },
        [_vm._m(0)]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticStyle: { color: "#FFF" } }, [
      _c("i", { staticClass: "fas fa-plus" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }