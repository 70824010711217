var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade bd-example-modal",
      attrs: {
        id: "create-tag-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("p", { staticClass: "modal-title text-bold" }, [
                _vm._v(_vm._s(_vm.$t("__Addlabel"))),
              ]),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", [
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  _vm._v(" " + _vm._s(_vm.$t("__requiredItem")) + " "),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-2",
                    attrs: { "data-translate": "_selectColor.edit" },
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__Labelname")) + " "),
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tagName,
                        expression: "tagName",
                      },
                    ],
                    staticClass: "form-control px-4 mx-3",
                    attrs: { type: "text", placeholder: _vm.$t("__Labelname") },
                    domProps: { value: _vm.tagName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.tagName = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-0",
                    attrs: { "data-translate": "_selectColor.edit" },
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__Chooselabelcolor")) + " "),
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "phone-screen mb-4" }, [
                    _c("div", { staticClass: "color-filters" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.colorOptions, function (color, index) {
                          return _c("li", { key: index }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.colorFilter,
                                  expression: "colorFilter",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "colorFilters",
                                id: color,
                              },
                              domProps: {
                                value: color,
                                checked: _vm._q(_vm.colorFilter, color),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.colorFilter = color
                                },
                              },
                            }),
                            _c("label", {
                              class: color,
                              attrs: { for: color, id: color },
                            }),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "ml-auto",
                      staticStyle: { "font-size": "0.8em" },
                    },
                    [
                      _c("span", { staticClass: "text-danger" }, [_vm._v("※")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "__Thenewlabelmustbereusedafterthestoragenode"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _c("label", { staticClass: "pt-3 mb-2" }, [_vm._v("Values")]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm ml-auto my-auto",
                      staticStyle: { height: "32px" },
                      on: {
                        click: function ($event) {
                          return _vm.addTagValue()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("__Add")) + " ")]
                  ),
                ]),
                _c("div", [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.TagValues, function (value, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "list-group-item p-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.TagValues[index],
                                expression: "TagValues[index]",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.TagValues[index] },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.TagValues,
                                  index,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button", id: "create-story-tag" },
                  on: {
                    click: function ($event) {
                      return _vm.createTag()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("__Createnewlabel")) + " ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }