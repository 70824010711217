var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("div", { staticClass: "text-center" }, [_vm._m(0)])
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            { attrs: { id: "myKanban" } },
            _vm._l(_vm.maxLevel + 1, function (index) {
              return _c(
                "div",
                {
                  key: "level" + index,
                  staticClass: "kanban-container",
                  attrs: { id: "level" + index },
                },
                [
                  _c("h5", { staticClass: "text-center" }, [
                    _vm._v("Lv" + _vm._s(index)),
                  ]),
                  _vm.scenario.settings &&
                  _vm.scenario.settings.level &&
                  _vm.scenario.settings.level[index - 1]
                    ? _c("div", [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.scenario.settings.level[index - 1]
                                    .enableSubForm,
                                expression:
                                  "scenario.settings.level[index-1].enableSubForm",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.scenario.settings.level[index - 1]
                                  .enableSubForm
                              )
                                ? _vm._i(
                                    _vm.scenario.settings.level[index - 1]
                                      .enableSubForm,
                                    null
                                  ) > -1
                                : _vm.scenario.settings.level[index - 1]
                                    .enableSubForm,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a =
                                      _vm.scenario.settings.level[index - 1]
                                        .enableSubForm,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.scenario.settings.level[
                                            index - 1
                                          ],
                                          "enableSubForm",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.scenario.settings.level[
                                            index - 1
                                          ],
                                          "enableSubForm",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.scenario.settings.level[index - 1],
                                      "enableSubForm",
                                      $$c
                                    )
                                  }
                                },
                                function ($event) {
                                  return _vm.logLevelsettings(index - 1)
                                },
                              ],
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("__enableSubForm")) + " "),
                        ]),
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.scenario.settings.level[index - 1]
                                    .enableMergeCard,
                                expression:
                                  "scenario.settings.level[index-1].enableMergeCard",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "mergeCard" + (index - 1),
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.scenario.settings.level[index - 1]
                                  .enableMergeCard
                              )
                                ? _vm._i(
                                    _vm.scenario.settings.level[index - 1]
                                      .enableMergeCard,
                                    null
                                  ) > -1
                                : _vm.scenario.settings.level[index - 1]
                                    .enableMergeCard,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.scenario.settings.level[index - 1]
                                      .enableMergeCard,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.scenario.settings.level[index - 1],
                                        "enableMergeCard",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.scenario.settings.level[index - 1],
                                        "enableMergeCard",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.scenario.settings.level[index - 1],
                                    "enableMergeCard",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "mergeCard" + (index - 1) } },
                            [_vm._v(_vm._s(_vm.$t("__mergeCard")))]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.scenario.settings &&
                  _vm.scenario.settings.level &&
                  _vm.scenario.settings.level[index - 1] &&
                  _vm.scenario.settings.level[index - 1].enableSubForm === true
                    ? _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.scenario.settings.level[index - 1]
                                  .subFormId,
                              expression:
                                "scenario.settings.level[index-1].subFormId",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("__enterSubformId"),
                          },
                          domProps: {
                            value:
                              _vm.scenario.settings.level[index - 1].subFormId,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.scenario.settings.level[index - 1],
                                "subFormId",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.scenario.settings &&
                  _vm.scenario.settings.level &&
                  _vm.scenario.settings.level[index - 1] &&
                  _vm.scenario.settings.level[index - 1].enableSubForm === true
                    ? _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.scenario.settings.level[index - 1]
                                  .subFormName,
                              expression:
                                "scenario.settings.level[index-1].subFormName",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("__enterSubformName"),
                          },
                          domProps: {
                            value:
                              _vm.scenario.settings.level[index - 1]
                                .subFormName,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.scenario.settings.level[index - 1],
                                "subFormName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.scenario.settings.level[index - 1]
                                    .enableMultipleSubtable,
                                expression:
                                  "scenario.settings.level[index-1].enableMultipleSubtable",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "multipleSubtable" + (index - 1),
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.scenario.settings.level[index - 1]
                                  .enableMultipleSubtable
                              )
                                ? _vm._i(
                                    _vm.scenario.settings.level[index - 1]
                                      .enableMultipleSubtable,
                                    null
                                  ) > -1
                                : _vm.scenario.settings.level[index - 1]
                                    .enableMultipleSubtable,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.scenario.settings.level[index - 1]
                                      .enableMultipleSubtable,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.scenario.settings.level[index - 1],
                                        "enableMultipleSubtable",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.scenario.settings.level[index - 1],
                                        "enableMultipleSubtable",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.scenario.settings.level[index - 1],
                                    "enableMultipleSubtable",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              attrs: { for: "multipleSubtable" + (index - 1) },
                            },
                            [_vm._v(_vm._s(_vm.$t("__enterSubformMapping")))]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "draggable",
                    {
                      staticClass: "kanban-board mr-5 p-4 list-group",
                      attrs: {
                        id: index,
                        handle: ".draggable-item",
                        group: "node",
                      },
                      on: {
                        change: _vm.nodeDragChangedHandler,
                        start: function ($event) {
                          _vm.drag = true
                        },
                        end: _vm.nodeDragEndHandler,
                      },
                      model: {
                        value: _vm.nodes,
                        callback: function ($$v) {
                          _vm.nodes = $$v
                        },
                        expression: "nodes",
                      },
                    },
                    _vm._l(_vm.nodes, function (nodeItem) {
                      return _c(
                        "div",
                        {
                          key: nodeItem.nodeId,
                          attrs: {
                            "ghost-class": "ghost",
                            id: nodeItem.nodeId,
                            name: nodeItem.level,
                          },
                        },
                        [
                          nodeItem.level == index
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "error list-group-item":
                                      nodeItem.error == 1,
                                    "list-group-item": nodeItem.error != 1,
                                    "draggable-item": _vm.draggableNode(
                                      nodeItem.nodeId
                                    ),
                                  },
                                },
                                [
                                  _vm.draggableNode(nodeItem.nodeId)
                                    ? _c("div", { staticClass: "float-left" }, [
                                        _c("a", [
                                          _c("i", {
                                            staticClass: "fas fa-align-justify",
                                          }),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "float-right" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-danger float-right",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteNode(nodeItem)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-trash text-danger",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "column mb-0 node-card",
                                      staticStyle: { padding: "0" },
                                      on: {
                                        contextmenu: function ($event) {
                                          $event.preventDefault()
                                          return _vm.$refs.menu.open($event, {
                                            nodeItem,
                                          })
                                        },
                                        click: function ($event) {
                                          return _vm.editNode(nodeItem, 0)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header item_handle drag_handler",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "1em",
                                                "font-weight": "bolder",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNodeCardSubTitle(
                                                    nodeItem
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header item_handle drag_handler pt-0",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8em",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNodeCardTitle(nodeItem)
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-body item_handle drag_handler",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "nodeIcon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.nodeIcon(nodeItem)
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn create-node-btn",
                      on: {
                        click: function ($event) {
                          return _vm.createNode(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("__createNewNode")) + " ")]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("vue-context", {
        ref: "menu",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ data }) {
              return [
                _c("li", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.copyNode(data.nodeItem)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("__Copycurrenttarget")) + " ")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm.componentKey
        ? _c("create-edit-form-node-modal", {
            key: _vm.componentKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              scenarioId: _vm.scenarioId,
              allNodes: _vm.nodes,
              nodeData: _vm.nodeModal,
              allTags: _vm.allTags,
              allVariables: _vm.allVariables,
              allscenario: _vm.allscenario,
              allSegments: _vm.allSegments,
              crosApiErrs: _vm.crosApiErrs,
              allVarForms: _vm.allVarForms,
            },
            on: { childToParent: _vm.onChildClick },
          })
        : _vm._e(),
      _c("create-tag-modal", { on: { newTag: _vm.newTag } }),
      _c("exist-tag-modal", {
        key: _vm.tagComponentKey,
        attrs: { allTags: _vm.allTags },
        on: { addTag: _vm.addTag },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner-border" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }