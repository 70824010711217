var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "side-bar",
        { attrs: { id: "sidebar" } },
        [
          _vm.$route.name != "account" && _vm.$route.name != "login"
            ? _c(
                "template",
                { slot: "links" },
                [
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/dashboard" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "tachometer-alt", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        { attrs: { "data-translate": "_topPage.sidebar" } },
                        [_vm._v(_vm._s(_vm.$t("__home")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/story" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "comment-medical", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        { attrs: { "data-translate": "_story.sidebar" } },
                        [_vm._v(_vm._s(_vm.$t("__dialogsetup")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/formStory" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "file", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        { attrs: { "data-translate": "_formStory.sidebar" } },
                        [_vm._v(_vm._s(_vm.$t("__formsetup")))]
                      ),
                    ],
                    1
                  ),
                  _vm.accountType() == "line"
                    ? _c(
                        "sidebar-link",
                        { attrs: { to: "/richmenu" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: "grip-horizontal",
                              "fixed-width": "",
                            },
                          }),
                          _c(
                            "span",
                            {
                              attrs: { "data-translate": "_richmenu.sidebar" },
                            },
                            [_vm._v(_vm._s(_vm.$t("__richMenu")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/tagList" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "tags", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        { attrs: { "data-translate": "_tagList.sidebar" } },
                        [_vm._v(_vm._s(_vm.$t("__tagList")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/segment" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "user-tag", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        { attrs: { "data-translate": "_userSegment.sidebar" } },
                        [_vm._v(_vm._s(_vm.$t("__segment")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/pushMessage" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "paper-plane", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        { attrs: { "data-translate": "_pushMsg.sidebar" } },
                        [_vm._v(_vm._s(_vm.$t("__pushMessage")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/data" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "file-export", "fixed-width": "" },
                      }),
                      _c("span", { attrs: { "data-translate": "" } }, [
                        _vm._v(_vm._s(_vm.$t("__exportData"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/appsConfig" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "fa-solid fa-cubes", "fixed-width": "" },
                      }),
                      _c("span", { attrs: { "data-translate": "" } }, [
                        _vm._v(_vm._s(_vm.$t("__appsConfig"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/externalApiConfig" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "external-link-square-alt",
                          "fixed-width": "",
                        },
                      }),
                      _c("span", { attrs: { "data-translate": "" } }, [
                        _vm._v(_vm._s(_vm.$t("__externalApiConfig"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "sidebar-link",
                    { attrs: { to: "/setting" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "cogs", "fixed-width": "" },
                      }),
                      _c(
                        "span",
                        {
                          attrs: { "data-translate": "_commonSetting.sidebar" },
                        },
                        [_vm._v(_vm._s(_vm.$t("__generalSetup")))]
                      ),
                    ],
                    1
                  ),
                  _vm.accountType() == "ig"
                    ? _c(
                        "sidebar-link",
                        { attrs: { to: "/metaUtils" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "magnet", "fixed-width": "" },
                          }),
                          _c("span", { attrs: { "data-translate": "" } }, [
                            _vm._v(_vm._s(_vm.$t("__metaUtils"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.accountType() == "line"
                    ? _c(
                        "sidebar-link",
                        { attrs: { to: "/userIcon" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "icons", "fixed-width": "" },
                          }),
                          _c(
                            "span",
                            {
                              attrs: { "data-translate": "_userIcon.sidebar" },
                            },
                            [_vm._v(_vm._s(_vm.$t("__iconSetting")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _c("dashboard-content", {
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSidebar.apply(null, arguments)
              },
            },
          }),
          _c("content-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }