var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "footer" }, [
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex flex-wrap justify-content-between",
        },
        [
          _c("div", { staticClass: "container my-auto" }, [
            _c("div", { staticClass: "copyright text-center my-auto" }, [
              _c("span", [_vm._v("Copyright © ASNET Co., Ltd. 2020")]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }