import { render, staticRenderFns } from "./PushMessageTable.vue?vue&type=template&id=b16a0f8e"
import script from "./PushMessageTable.vue?vue&type=script&lang=js"
export * from "./PushMessageTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\projects\\02_chatAnnie\\git\\chatbot_new\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b16a0f8e')) {
      api.createRecord('b16a0f8e', component.options)
    } else {
      api.reload('b16a0f8e', component.options)
    }
    module.hot.accept("./PushMessageTable.vue?vue&type=template&id=b16a0f8e", function () {
      api.rerender('b16a0f8e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PushMessage/Component/PushMessageTable.vue"
export default component.exports