var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "component",
      staticClass: "dropdown",
      class: { show: _vm.isOpen },
      on: { click: _vm.toggleDropDown },
    },
    [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle btn-rotate",
          class: _vm.titleClasses,
          attrs: { "data-toggle": "dropdown" },
        },
        [
          _vm._t("title", function () {
            return [
              _c("i", { class: _vm.icon }),
              _c("span", { staticClass: "notification" }, [
                _vm._v(_vm._s(_vm.title) + " "),
                _c("b", { staticClass: "caret" }),
              ]),
            ]
          }),
        ],
        2
      ),
      _c(
        "ul",
        { staticClass: "dropdown-menu", class: { show: _vm.isOpen } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }