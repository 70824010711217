var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { staticClass: "main-content" },
    [
      _c(
        "div",
        { staticClass: "border border-success rounded px-4 py-4 mb-3" },
        [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.userIconList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "col-3 m-3 border rounded-lg user-icon-block shadow",
                  on: {
                    click: function ($event) {
                      return _vm.editUserIcon(item)
                    },
                  },
                },
                [
                  _c("img", { staticClass: "rounded-circle account-type" }),
                  _c("div", { staticClass: "text-center pt-4" }, [
                    _c("img", {
                      staticClass: "rounded-circle user-icon-img",
                      attrs: { src: item.url },
                    }),
                  ]),
                  _c(
                    "p",
                    { staticClass: "text-center py-3 font-weight-bold" },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c("div", { staticClass: "btn-follow" }, [
        _c(
          "a",
          {
            staticStyle: { color: "#fff" },
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.createUserIcon()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-plus" })]
        ),
      ]),
      _c("create-user-icon-modal", {
        ref: "createUserIconModal",
        attrs: { saveUserIconData: _vm.modalData },
        on: {
          saveUserIcon: _vm.saveUserIcon,
          deleteUserIcon: _vm.deleteUserIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }